import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import countLoading from '../../../assets/images/bloc-calculette@2x.png'

import TableHeadMyCounts from './TableHead/TableHeadMyCounts'
import TableHeadUsers from './TableHead/TableHeadUsers'
import ActionButtonsMenu from './TableRow/ActionButtonsMenu'
import TableRowMyCounts from './TableRow/TableRowMyCounts'
import TableRowUsers from './TableRow/TableRowUsers'

import {
  allCountsDataSelector,
  loadingApiSelector as loadingApiCountsSelector,
  myCountsDataSelector,
  rowSelectedSelector,
  setShowPopin,
} from '../../../redux/reducers/Page/Counts/pageCounts.reducer'
import {
  loadingApiSelector as loadingApiUsersSelector,
  rowSelectedSelector as rowSelectedUsersSelector,
  usersDataSelector,
} from '../../../redux/reducers/Page/Users/pageUsers.reducer'
import {
  currentMarketSelector,
  userIdSelector,
} from '../../../redux/reducers/UserConfig/userConfig.reducer'

import { getAllCounts, getMyCounts } from '../../../services/countApi'

import { ITEMS_PER_PAGE } from '../../../utils/variables'

import {
  allBoostDataSelector,
  boostErrorsSelector,
  loadingApiBoostSelector,
  myBoostDataSelector,
  rowSelectedBoostSelector,
} from '../../../redux/reducers/Page/Boosts/pageBoost.reducer'
import { BOOST_SOCIAL_IMMO_NEW } from '../../../routes.constants'
import {
  getAllBoostNewBuild,
  getMyBoostNewBuild,
} from '../../../services/boostSocialImmoApi'
import { getAllUsers } from '../../../services/userApi'
import {
  BoostBo,
  CountBo,
  Market,
  UserBo,
} from '../../../utils/myAdfactoryApi/swaggerApi'
import OverlayDeleteCount from '../../Overlay/OverlayDeleteCount/OverlayDeleteCount'
import OverlayEditProfil from '../../Overlay/OverlayEditUser/OverlayEditUser'
import { LoadingList } from './LoadingList/LoadingList'
import { TableHeadBoost } from './TableHead/TableHeadBoost'
import { TableRowBoost } from './TableRow/TableRowBoost/TableRowBoost'

type BodyListProps = {
  col: any
  currentPage: number
  mode: string
  setCol: any
  sortBy: { [key: string]: string }
  setSortBy: Dispatch<SetStateAction<{ [key: string]: string }>>
}

const BodyList = ({
  col,
  currentPage,
  mode,
  setCol,
  sortBy,
  setSortBy,
}: BodyListProps): React.ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [actionButtonsMenuOpen, setActionButtonsMenuOpen] = useState(false)
  const [overlayEditUser, setOverlayEditUser] = useState(false)
  const [overlayDeleteCount, setOverlayDeleteCount] = useState(false)

  const { loader, data } = elaborateLoaderAndData(mode)
  const loadingSelector = loader
  const dataSelector = data

  const currentMarket: Market = useSelector(currentMarketSelector)
  const loading = useSelector(loadingSelector)
  const dataList: any = useSelector(dataSelector)
  const userId = useSelector(userIdSelector)
  const countsRowSelected = useSelector(rowSelectedSelector)
  const userRowSelected = useSelector(rowSelectedUsersSelector)
  const boostsRowSelected = useSelector(rowSelectedBoostSelector)
  const boostErrors = useSelector(boostErrorsSelector)

  const rowSelected = elaborateRowSelected(
    mode,
    countsRowSelected,
    userRowSelected,
    boostsRowSelected
  )

  const getData = (page: number, order?: string, sort?: string) => {
    elaborateGetData(mode, dispatch, page, userId, order, sort, currentMarket)
  }

  const closePopin = () => {
    dispatch(setShowPopin(false))
    setActionButtonsMenuOpen(false)
  }

  useEffect(() => {
    if (mode === 'users') getData(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, mode])

  if (loading === 'pending' || loading === 'loading') {
    return <LoadingList />
  }

  const emptyDataLists = (): React.ReactNode => (
    <div className="countLoading">
      <img src={countLoading} alt="Liste de résultats vide" />
      {mode === 'users' && <p>Aucun utilisateur trouvé</p>}
      {(mode === 'myCounts' || mode === 'allCounts') && (
        <p>Aucun comptage effectué</p>
      )}
      {(mode === 'myBoost' || mode === 'allBoost') && (
        <p>Aucun Boost effectué</p>
      )}
    </div>
  )

  if ((mode === 'myBoost' || mode === 'allBoost') && boostErrors) {
    return (
      <ErrorOccurred>
        <p>Une erreur est survenue</p>
      </ErrorOccurred>
    )
  }

  return (
    <div className="listsBodyWrapper" data-testid="bodyList">
      {dataList && dataList.length === 0 ? (
        emptyDataLists()
      ) : (
        <table>
          {(mode === 'myCounts' || mode === 'allCounts') && (
            <TableHeadMyCounts
              setCol={setCol}
              col={col}
              setSortBy={setSortBy}
              sortBy={sortBy}
              getData={getData}
              currentPage={currentPage}
            />
          )}
          {mode === 'users' && (
            <TableHeadUsers
              setCol={setCol}
              col={col}
              setSortBy={setSortBy}
              sortBy={sortBy}
              getData={getData}
              currentPage={currentPage}
            />
          )}
          {(mode === 'myBoost' || mode === 'allBoost') && (
            <TableHeadBoost
              setCol={setCol}
              col={col}
              setSortBy={setSortBy}
              sortBy={sortBy}
              getData={getData}
              currentPage={currentPage}
            />
          )}
          <tbody>
            {(mode === 'myCounts' || mode === 'allCounts') &&
              loading !== 'pending' &&
              dataList &&
              dataList.length !== 0 &&
              dataList.map((myCount: CountBo) => (
                <TableRowMyCounts
                  myCount={myCount}
                  key={myCount.id}
                  setActionButtonsMenuOpen={setActionButtonsMenuOpen}
                />
              ))}
            {(mode === 'myBoost' || mode === 'allBoost') &&
              loading !== 'pending' &&
              dataList &&
              dataList.length !== 0 &&
              dataList.map((boostBo: BoostBo) => (
                <TableRowBoost
                  boostBo={boostBo}
                  key={boostBo.id}
                  setActionButtonsMenuOpen={setActionButtonsMenuOpen}
                />
              ))}
            {mode === 'users' &&
              loading !== 'pending' &&
              dataList.length !== 0 &&
              dataList.map((user: UserBo) => (
                <TableRowUsers
                  user={user}
                  key={user.id}
                  setActionButtonsMenuOpen={setActionButtonsMenuOpen}
                />
              ))}
          </tbody>
        </table>
      )}
      {actionButtonsMenuOpen && (
        <ActionButtonsMenu
          idMaf={rowSelected.idMaf}
          mafProduct={rowSelected.mafProduct}
          left={rowSelected.x}
          top={rowSelected.y}
          closePopin={closePopin}
          actionType={mode}
          editUser={() => setOverlayEditUser(true)}
          deleteCount={() => setOverlayDeleteCount(true)}
          editBoost={() => {
            sessionStorage.setItem(
              'boostSocialImmoData',
              JSON.stringify({
                clientId: rowSelected.clientId,
                clientName: rowSelected.clientName,
                contractId: rowSelected.contractId,
                nbMaxBoost: rowSelected.nbMaxBoost,
              })
            )
            history.push(BOOST_SOCIAL_IMMO_NEW)
          }}
        />
      )}
      {overlayEditUser && (
        <OverlayEditProfil closeOverlay={() => setOverlayEditUser(false)} />
      )}
      {overlayDeleteCount && (
        <OverlayDeleteCount
          closeOverlay={() => setOverlayDeleteCount(false)}
          idMaf={rowSelected.idCount}
        />
      )}
    </div>
  )
}

// Styled component
const ErrorOccurred = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

export default BodyList

const elaborateLoaderAndData = (mode: string) => {
  let loader: any
  let data: any
  switch (mode) {
    case 'myCounts':
      loader = loadingApiCountsSelector
      data = myCountsDataSelector
      break
    case 'allCounts':
      loader = loadingApiCountsSelector
      data = allCountsDataSelector
      break
    case 'myBoost':
      loader = loadingApiBoostSelector
      data = myBoostDataSelector
      break
    case 'allBoost':
      loader = loadingApiBoostSelector
      data = allBoostDataSelector
      break
    case 'users':
      loader = loadingApiUsersSelector
      data = usersDataSelector
      break
    default:
      break
  }
  return { loader, data }
}
const elaborateRowSelected = (
  mode: string,
  countsRowSelected: any,
  userRowSelected: any,
  boostsRowSelected: any
) => {
  let row: any
  if (mode === 'myCounts' || mode === 'allCounts') {
    row = countsRowSelected
  } else if (mode === 'users') {
    row = userRowSelected
  } else if (mode === 'myBoost' || mode === 'allBoost') {
    row = boostsRowSelected
  }
  return row
}

const elaborateGetData = (
  mode: string,
  dispatch: any,
  page: number,
  userId: any,
  order: string | undefined,
  sort: string | undefined,
  market: Market
) => {
  const itemsPerPage = ITEMS_PER_PAGE
  const clientId = undefined
  const productName = undefined
  const clientName = undefined
  switch (mode) {
    case 'myCounts':
      dispatch(
        getMyCounts({
          page: page,
          itemsPerPage,
          clientId: userId,
          order: order,
          orderDirection: sort,
          market,
          searchCount: '',
        })
      )
      break
    case 'allCounts':
      dispatch(
        getAllCounts({
          page,
          itemsPerPage,
          clientId,
          productName,
          market,
          clientName,
          order,
          orderDirection: sort,
          searchCount: '',
        })
      )
      break
    case 'myBoost':
      dispatch(getMyBoostNewBuild(null))
      break
    case 'allBoost':
      dispatch(getAllBoostNewBuild(null))
      break
    case 'users':
      dispatch(
        getAllUsers({
          page,
          itemsPerPage,
          order: order,
          orderDirection: sort,
          searchUser: '', // We don't use redux userSearchSelector here because we don't need to use its value when we change the page
        })
      )
      break
    default:
      break
  }
}

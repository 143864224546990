import { Icon, useClickOutside } from '@ui-library/core'
import { CloseRegular } from '@ui-library/icons'
import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  AutoComplete,
  AutoCompleteDisplayInput,
  AutoCompleteInput,
  AutoCompleteLabel,
  AutoCompleteList,
  AutoCompletePlaceholderStrong,
  AutoCompleteWrapperInput,
  ClientName,
  NoItem,
  RemoveItem,
  ResultList,
} from '../styled'
import ItemList from './ItemList/ItemList'
import ItemListLoader from './ItemList/ItemListLoader'

import useDebounce from '../../../hooks/useDebounce'
import { getClientsList } from '../../../services/expert360Api'

import { boostErrorsSelector } from '../../../redux/reducers/Page/Boosts/pageBoost.reducer'
import { backendHostSelector } from '../../../redux/reducers/UserConfig/userConfig.reducer'
import { getClientsNewBuild } from '../../../services/boostSocialImmoApi'
import {
  NewBuildAgency,
  type ClientBo,
} from '../../../utils/myAdfactoryApi/swaggerApi'
import { capitalize } from '../../../utils/tsUtils'

type AutocompleteUniqueProps = {
  error: boolean
  label: string
  placeholder: string
  autocompleteCallback: (value: ClientBo[] | NewBuildAgency[]) => void
  noResultFound: string
  source?: 'expert360' | 'boostSocialImmo'
}

const AutocompleteUnique = ({
  error,
  label,
  placeholder,
  autocompleteCallback,
  noResultFound,
  source = 'expert360',
}: AutocompleteUniqueProps) => {
  const dispatch = useDispatch()
  const boostErrors = useSelector(boostErrorsSelector)

  const autocompleteListRef: React.RefObject<HTMLDivElement> = useRef(null)
  const [suggestions, setSuggestions] = useState<ClientBo[] | null>(null)
  const [newBuildSuggestions, setNewBuildSuggestions] = useState<
    NewBuildAgency[] | null
  >(null)
  const [errorAutocomplete, setErrorAutocomplete] = useState<boolean>(false)
  const [isAutocompleteFocus, setIsAutocompleteFocus] = useState<boolean>(false)
  const [myList, setMyList] = useState<ClientBo[] | NewBuildAgency[]>([])
  const [isAutocompleteListOpen, setIsAutocompleteListOpen] =
    useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [isMouseDownOnInput, setIsMouseDownOnInput] = useState<boolean>(false)

  //-----------------------------------------------------------------------
  // Fetch data with debounce method

  const backendHost = useSelector(backendHostSelector)
  const debouncedQuery = useDebounce(inputValue, 200)

  useEffect(() => {
    if (source && debouncedQuery.length >= 2) {
      doDebounce()
    } else {
      setSuggestions(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery])

  const doDebounce = (): void => {
    if (source === 'expert360') {
      getClientsList({ clientId: debouncedQuery, state: backendHost }).then(
        (res: any) => {
          setSuggestions(res)
        }
      )
    }
    if (source === 'boostSocialImmo') {
      dispatch(getClientsNewBuild({ clientId: debouncedQuery })).then(
        (res: any) => {
          setNewBuildSuggestions(res?.payload)
        }
      )
    }
  }

  //-----------------------------------------------------------------------
  // Check value of inputValue and myList, to close autocomplete if needed

  useEffect(() => {
    if (inputValue.length < 2 && myList.length === 0) {
      closeAutocompleteList()
    }
  }, [inputValue, myList, isAutocompleteListOpen])

  //-----------------------------------------------------------------------
  // Update state autocomplete error depending on props

  useEffect(() => {
    setErrorAutocomplete(error)
  }, [error])

  //-----------------------------------------------------------------------
  // Add EventListener/function to track mouseClick and set

  const mouseDownOnInput = () => {
    setIsMouseDownOnInput(true)
  }
  const mouseUpOnDocument = () => {
    setIsMouseDownOnInput(false)
  }

  useEffect(() => {
    const autocompleteRef = autocompleteListRef.current
    if (autocompleteListRef && autocompleteRef) {
      autocompleteRef?.addEventListener('mousedown', mouseDownOnInput)
      document.addEventListener('mouseup', mouseUpOnDocument)
    }

    return () => {
      autocompleteRef?.removeEventListener('mousedown', mouseDownOnInput)
      document.removeEventListener('mouseup', mouseUpOnDocument)
    }
  })

  //-----------------------------------------------------------------------
  // Input's interaction method

  const inputFocus = () => {
    setIsAutocompleteFocus(true)
    if (myList.length !== 0 || inputValue.length >= 2) {
      openAutocompleteList()
    }
    !isAutocompleteListOpen && setInputValue('')
  }

  const inputFocusOut = () => {
    if (!isMouseDownOnInput) {
      setIsAutocompleteFocus(false)
      closeAutocompleteList()
    }
  }

  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    if (event.target.value.length >= 2) {
      setSuggestions(null)
      openAutocompleteList()
    }
  }

  //-----------------------------------------------------------------------
  // Define Autocomplete Comportment state / Open or close

  const openAutocompleteList = () => {
    setIsAutocompleteListOpen(true)
  }
  const closeAutocompleteList = () => {
    setIsAutocompleteListOpen(false)
  }

  //-----------------------------------------------------------------------
  // Add Elem to MyList

  const addElemToMyList = (elem: ClientBo | NewBuildAgency) => {
    const newMyList = [elem]
    setMyList(newMyList)
    autocompleteCallback(newMyList)
    setIsAutocompleteFocus(false)
    closeAutocompleteList()
  }

  //-----------------------------------------------------------------------
  // remove Elem to MyList

  const removeElemToMyList = () => {
    const newMyList: ClientBo[] = []
    setMyList(newMyList)
    autocompleteCallback(newMyList)

    if (inputValue.length < 2) {
      setIsAutocompleteFocus(false)
    }
  }

  //-----------------------------------------------------------------------
  // CustomHooks from Ui library / Close Select when user click outside of select

  useClickOutside([autocompleteListRef], inputFocusOut)

  //-----------------------------------------------------------------------
  // Return part

  return (
    <AutoComplete data-testid="autocompleteUnique">
      <AutoCompleteLabel>
        <span>{label}</span>
      </AutoCompleteLabel>
      <div ref={autocompleteListRef}>
        <AutoCompleteWrapperInput $error={errorAutocomplete}>
          <AutoCompleteDisplayInput
            $displayInputHide={isAutocompleteFocus}
            $noIcon
          >
            {myList.length === 0 && (
              <div>
                <span>{placeholder}</span>
              </div>
            )}
            {myList.length === 1 && (
              <div>
                <AutoCompletePlaceholderStrong>
                  {myList[0].clientId}
                </AutoCompletePlaceholderStrong>
                <ClientName>
                  {myList[0].clientName &&
                    capitalize(myList[0].clientName.toLowerCase())}
                </ClientName>
                <AutoCompletePlaceholderStrong>
                   {'contractId' in myList[0] && ` ${myList[0].contractId}`}
                </AutoCompletePlaceholderStrong>
              </div>
            )}
          </AutoCompleteDisplayInput>
          <AutoCompleteInput
            data-testid="autocompleteInput"
            id="inputWithNoIcon"
            type="text"
            onFocus={inputFocus}
            onChange={(e) => inputChange(e)}
            value={inputValue}
          />
          {myList.length !== 0 && (
            <RemoveItem
              onClick={() => {
                removeElemToMyList()
              }}
            >
              <Icon icon={CloseRegular} />
            </RemoveItem>
          )}
        </AutoCompleteWrapperInput>
        {isAutocompleteListOpen && (
          <AutoCompleteList>
            {inputValue !== null && inputValue.length >= 2 && (
              <dl>
                {source === 'expert360' && (
                  <ResultList>
                    {suggestions !== null && suggestions.length === 0 ? (
                      <NoItem>{noResultFound}</NoItem>
                    ) : (
                      <>
                        {suggestions === undefined || suggestions === null ? (
                          <ItemListLoader />
                        ) : (
                          suggestions.map((value) => (
                            <ItemList
                              value={value}
                              inputValue={inputValue}
                              myList={myList}
                              addElemToMyList={(elem) => {
                                addElemToMyList(elem)
                              }}
                              key={value.id}
                            />
                          ))
                        )}
                      </>
                    )}
                  </ResultList>
                )}
                {source === 'boostSocialImmo' &&
                  displayErrorOrResult(
                    boostErrors,
                    newBuildSuggestions,
                    noResultFound,
                    inputValue,
                    myList,
                    addElemToMyList
                  )}
              </dl>
            )}
          </AutoCompleteList>
        )}
      </div>
    </AutoComplete>
  )
}

export default AutocompleteUnique

const displayErrorOrResult = (
  boostErrors: any,
  newBuildSuggestions: NewBuildAgency[] | null,
  noResultFound: string,
  inputValue: string,
  myList: ClientBo[] | NewBuildAgency[],
  addElemToMyList: (elem: ClientBo | NewBuildAgency) => void
): React.ReactElement => (
  <ResultList>
    {boostErrors ? (
      <NoItem>
        Une erreur est survenue lors de la récupération des données
      </NoItem>
    ) : (
      noItemOrResults(
        newBuildSuggestions,
        noResultFound,
        inputValue,
        myList,
        addElemToMyList
      )
    )}
  </ResultList>
)

const noItemOrResults = (
  newBuildSuggestions: NewBuildAgency[] | null,
  noResultFound: string,
  inputValue: string,
  myList: NewBuildAgency[] | ClientBo[],
  addElemToMyList: (elem: ClientBo | NewBuildAgency) => void
): React.ReactNode =>
  newBuildSuggestions !== null && newBuildSuggestions?.length === 0 ? (
    <NoItem>{noResultFound}</NoItem>
  ) : (
    <>
      {newBuildSuggestions === undefined || newBuildSuggestions === null ? (
        <ItemListLoader />
      ) : (
        newBuildSuggestions?.map((value, index) => (
          <ItemList
            value={value}
            inputValue={inputValue}
            myList={myList}
            addElemToMyList={(elem) => {
              addElemToMyList(elem)
            }}
            // eslint-disable-next-line
            key={index}
          />
        ))
      )}
    </>
  )

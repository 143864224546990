import { Icon } from '@ui-library/core'
import { CheckBold, CloseBold } from '@ui-library/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import BodyList from '../../../components/Lists/BodyList/BodyList'
import HeaderList from '../../../components/Lists/HeaderList/HeaderList'
import {
  BoostRecordedNotification,
  IconDiv,
  ToasterText,
  ToasterTextContainer,
} from '../../../components/Products/BoostSocialImmo/FixedBoostPrograms/FixedBoostPrograms'
import {
  boostNotificationSelector,
  boostNotificationVisibleSelector,
} from '../../../redux/reducers/Page/Boosts/pageBoost.reducer'
import {
  currentMarketSelector,
  tabListBoostSelector,
  userProfileSelector,
} from '../../../redux/reducers/UserConfig/userConfig.reducer'
import {
  getAllBoostNewBuild,
  getMyBoostNewBuild,
} from '../../../services/boostSocialImmoApi'
import { TypeProfil } from '../../../utils/myAdfactoryApi/swaggerApi'
import { choicesBoost } from '../../../utils/variables'

const MyBoost = (): React.ReactElement => {
  const dispatch = useDispatch()
  const userProfile = useSelector(userProfileSelector)
  const currentMarket = useSelector(currentMarketSelector)
  const tabListBoost = useSelector(tabListBoostSelector)

  const boostNotification = useSelector(boostNotificationSelector)
  const boostNotificationVisible = useSelector(boostNotificationVisibleSelector)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [sortBy, setSortBy] = useState<{ [key: string]: string }>({
    ClientId: 'desc',
    Contrat: 'desc',
    ProductName: 'desc',
    ProgramsCount: 'desc',
    CreatedDate: 'desc',
    BsiId: 'desc',
  })
  const [col, setCol] = useState<string>('CreatedDate')

  const buttonObj = {
    haveButton: true,
    btnLabel: 'Nouveau Boost',
  }

  const fetchBoost = () => {
    // fetch data
    if (tabListBoost === 'myBoost') {
      dispatch(getMyBoostNewBuild(null))
    } else if (tabListBoost === 'allBoost') {
      dispatch(getAllBoostNewBuild(null))
    }
  }

  const refBoostNotificationToaster = useRef(null)

  useEffect(() => {
    fetchBoost()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarket, userProfile])

  return (
    <>
      <section className="listsPage" data-testid="listsPage">
        <div className="listsContentWrapper">
          {userProfile.typeProfil === TypeProfil.AdfactoryOperations ? (
            <HeaderList
              listType="boost"
              setCurrentPage={setCurrentPage}
              buttonObj={buttonObj}
              title="Liste des Boost"
              choices={choicesBoost}
              userProfile={userProfile}
            />
          ) : (
            <HeaderList
              listType="boost"
              setCurrentPage={setCurrentPage}
              buttonObj={buttonObj}
              title="Liste des Boost"
              choices={[choicesBoost[0]]}
              userProfile={userProfile}
            />
          )}
          <BodyList
            currentPage={currentPage}
            setSortBy={setSortBy}
            setCol={setCol}
            sortBy={sortBy}
            col={col}
            mode={tabListBoost}
          />
        </div>
      </section>
      <CSSTransition
        nodeRef={refBoostNotificationToaster}
        in={boostNotificationVisible}
        timeout={400}
        classNames="visible"
        appear
        unmountOnExit
      >
        <BoostFooterWrapper ref={refBoostNotificationToaster}>
          <BoostRecordedNotification
            data-testid="notificationSavedCount"
            className="visible"
            $error={boostNotification?.statut === 'error'}
          >
            <IconDiv $error={boostNotification?.statut === 'error'}>
              <Icon
                icon={
                  boostNotification?.statut === 'error' ? CloseBold : CheckBold
                }
              />
            </IconDiv>
            <ToasterTextContainer>
              <strong>{boostNotification?.title}</strong>
              <ToasterText>{boostNotification?.description}</ToasterText>
            </ToasterTextContainer>
          </BoostRecordedNotification>
        </BoostFooterWrapper>
      </CSSTransition>
    </>
  )
}

export default MyBoost

const BoostFooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 256px;
  padding: 1em 2.5em;
  width: 100%;
  height: 88px;
  background: transparent;
`

import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  boostErrorsSelector,
  loadingApiBoostSelector,
} from '../../../redux/reducers/Page/Boosts/pageBoost.reducer'
import { getAdsByClient } from '../../../services/boostSocialImmoApi'
import {
  ContractType,
  NewBuildAgency,
  Programme,
} from '../../../utils/myAdfactoryApi/swaggerApi'
import DelayedContent from '../../DelayedContent/DelayedContent'
import { LoadingList } from '../../Lists/BodyList/LoadingList/LoadingList'
import { OverlayBoostPrograms } from '../../Overlay/OverlayBoostPrograms/OverlayBoostPrograms'
import { ThBlue, ThCellFix } from '../../Tables/common/TableHeadCssProps'
import { BoostSocialImmoHeaderTitle } from './BoostSocialImmoHeaderTitle/BoostSocialImmoHeaderTitle'
import { BoostSocialImmoTableBody } from './BoostSocialImmoTableBody/BoostSocialImmoTableBody'
import { DownSaleBanner } from './DownSaleBanner/DownSaleBanner'
import { FixedBoostPrograms } from './FixedBoostPrograms/FixedBoostPrograms'

const BoostSocialImmo = (): React.ReactElement => {
  const dispatch = useDispatch()

  const [apiResponse, setApiResponse] = useState<any>()
  const [programs, setPrograms] = useState<Programme[]>([])
  const [downSaleDate, setDownSaleDate] = useState<string | undefined>(
    undefined
  )
  const [bsiId, setBsiId] = useState<number>(0)
  const [contractId, setContractId] = useState<number>(0)
  const [contractType, setContractType] = useState<ContractType>(
    'Bronze' as ContractType
  )
  const [nbDownSaleMaxBoost, setNbDownSaleMaxBoost] = useState<number>(0)
  const [selectedProgramsCount, setSelectedProgramsCount] = useState<number>(0)
  const [shouldDisplayNotification, setShouldDisplayNotification] =
    useState(false)

  const [overlayBoostPrograms, setOverlayBoostPrograms] = useState(false)
  const isVisibleArray = Array(programs?.length).fill(false)
  const [isVisible, setIsVisible] = useState<boolean[]>(isVisibleArray)

  const boostSocialImmoData: NewBuildAgency = JSON.parse(
    sessionStorage.getItem('boostSocialImmoData') ?? '{}'
  )

  const loading = useSelector(loadingApiBoostSelector)
  const boostErrors = useSelector(boostErrorsSelector)

  const [mainImgArray, setMainImgArray] = useState<(string | undefined)[]>([])

  useEffect(() => {
    dispatch(
      getAdsByClient({ clientId: boostSocialImmoData.clientId ?? '', contractId: boostSocialImmoData.contractId })
    ).then((response: any) => {
      setApiResponse(response?.payload)
      const progs: Programme[] = response?.payload?.programmes
      setPrograms(progs)
      setSelectedProgramsCount(progs?.filter((p) => p.isChecked).length)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setMainImgArray(computeMainImages(progs))
      setBsiId(response?.payload?.bsiId)
      setContractId(response?.payload?.contractId)
      setContractType(response?.payload?.contractType)

      setNbDownSaleMaxBoost(response?.payload?.nbDownsaleMaxBoost)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boostSocialImmoData.clientId, dispatch])

  useMemo(() => {
    if (!apiResponse) return
    if (apiResponse.downSaleDate) {
      setDownSaleDate(apiResponse.downSaleDate)
    }
  }, [apiResponse])

  return (
    <>
      <section className="listsPage">
        <div className="listsContentWrapper">
          <div className="listsHeaderWrapper" data-testid="headerList">
            <div className="listsHeader">
              <BoostSocialImmoHeaderTitle
                boostSocialImmoData={boostSocialImmoData}
                selectedProgramsCount={selectedProgramsCount}
                downSaleDate={downSaleDate}
              />
            </div>
          </div>
          <div className="listsBodyWrapper">
            {programs?.length === 0 &&
            (loading === 'pending' || loading === 'loading') ? (
              <LoadingList centered />
            ) : (
              <>
                {downSaleDate && (
                  <div style={{ margin: '-3.25em 0 3.25em 0' }}>
                    <DelayedContent delay={3000}>
                      <DownSaleBanner nbMaxAfterDownSale={nbDownSaleMaxBoost} />
                    </DelayedContent>
                  </div>
                )}
                {boostErrors ? (
                  <ErrorOccurred>
                    <p>Une erreur est survenue</p>
                  </ErrorOccurred>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <ThBlue>Id Programme</ThBlue>
                        <ThBlue>Projet</ThBlue>
                        <ThBlue>Localité</ThBlue>
                        <ThBlue>Annonce Url</ThBlue>
                        <ThCellFix>Statut</ThCellFix>
                        {/* If dowSaleDate then display column */}
                        {downSaleDate && <ThBlue>À conserver</ThBlue>}
                      </tr>
                    </thead>
                    <tbody>
                      <BoostSocialImmoTableBody
                        programs={programs}
                        setPrograms={setPrograms}
                        setIsVisible={setIsVisible}
                        isVisible={isVisible}
                        mainImgArray={mainImgArray}
                        boostSocialImmoData={boostSocialImmoData}
                        setSelectedProgramsCount={setSelectedProgramsCount}
                        setShouldDisplayNotification={
                          setShouldDisplayNotification
                        }
                        downSaleDate={downSaleDate}
                      />
                    </tbody>
                  </table>
                )}
              </>
            )}
            {/* Spacer : Div to prevent the FixedBoostPrograms from covering the last row of the table */}
            <div style={{ height: '200px' }} />{' '}
          </div>
        </div>
      </section>
      <FixedBoostPrograms
        maxProgramsCount={boostSocialImmoData.nbMaxBoost ?? 0}
        notificationVisible={shouldDisplayNotification}
        setOverlayBoostPrograms={() => setOverlayBoostPrograms(true)}
        buttonDisabled={
          boostErrors ||
          selectedProgramsCount === 0 ||
          (downSaleDate !== null &&
            programs?.filter((p) => p.toKeep).length > nbDownSaleMaxBoost)
        }
        loading={loading}
      />
      {overlayBoostPrograms && (
        <OverlayBoostPrograms
          closeOverlay={() => setOverlayBoostPrograms(false)}
          programs={programs}
          bsiId={bsiId}
          downSellDate={downSaleDate}
          contractId={contractId}
          contractType={contractType}
        />
      )}
    </>
  )
}

// Styled component
const ErrorOccurred = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: ${(props) => props.theme.global.colorPalette.grey};
`

export default BoostSocialImmo

const computeMainImages = (programs: Programme[]) =>
  programs.map((program) =>
    program.images?.substring(0, program.images.indexOf(',https://'))
  )

import { Button } from '@ui-library/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addCurrentMarket,
  addJobAndMarketToUserProfile,
  userProfileSelector,
} from '../../../redux/reducers/UserConfig/userConfig.reducer'
import { createOrUpdateUser } from '../../../services/userApi'
import { UserInformation } from '../../../types/users'
import { Market, TypeProfil } from '../../../utils/myAdfactoryApi/swaggerApi'
import { jobList, marketList } from '../../../utils/variables'
import Select from '../../Select/Select'
import Overlay from '../Overlay'

type ElemSelectType = {
  label: string
  value: string
}

const marketListLabels = marketList.map((m) => m.label)
const customSort = (a: ElemSelectType, b: ElemSelectType) => {
  const indexA = marketListLabels.indexOf(a.label)
  const indexB = marketListLabels.indexOf(b.label)

  if (indexA === -1) {
    return 1
  }
  if (indexB === -1) {
    return -1
  }
  return indexA - indexB
}

const OverlayFirstConnection = (): React.ReactElement => {
  const dispatch = useDispatch()
  const userProfile = useSelector(userProfileSelector)
  const [jobSelected, setJobSelected] = useState<ElemSelectType | null>(null)
  const [marketSelected, setMarketSelected] = useState<ElemSelectType[]>([])

  //-----------------------------------------------------------------------
  // Functions

  const handleJobChange = (elem: ElemSelectType[]) => {
    if (elem.length > 0) {
      setJobSelected(elem[0])
    }
  }

  const handleMarketSelect = (selected: ElemSelectType[]) => {
    selected.sort(customSort)
    setMarketSelected(selected)
  }

  //-----------------------------------------------------------------------
  // Trigger when user click on validate button / Dispatch new informations to userProfile

  const processInformations = () => {
    const userInformation: UserInformation = {
      email: userProfile.email,
      lastName: userProfile.lastName,
      firstName: userProfile.firstName,
      typeProfil: jobSelected?.value as TypeProfil | undefined,
      markets:
        jobSelected?.value !== jobList[0].value
          ? (marketList.map((elem) => elem.value) as Market[])
          : (marketSelected.map((elem) => elem.value) as Market[]),
      roles: userProfile.roles,
    }
    dispatch(createOrUpdateUser({ userInformation }))
    dispatch(addJobAndMarketToUserProfile(userInformation))

    if (marketSelected?.[0]) {
      dispatch(addCurrentMarket(marketSelected[0]?.value))
    }
  }

  return (
    <Overlay>
      <div
        className="overlayFirstConnection"
        data-testid="overlay-first-connection"
      >
        <div className="wrapperOverlayFirstConnection">
          <h2 data-testid="helloFirstConnection">
            Bonjour {userProfile.firstName},
          </h2>
          <p>
            Pour accéder à un espace personnalisé, répondez aux questions
            suivantes
          </p>
          <div
            className="wrapperSelectJob"
            data-testid="selectJobFirstConnection"
          >
            <Select
              options={jobList}
              label="Votre métier"
              placeholder="Ex : Commerce"
              onChange={handleJobChange}
              value={jobSelected ? [jobSelected] : null}
            />
            {jobSelected !== null && jobSelected.value === jobList[0].value && (
              <div className="wrapperSelectMarket">
                <Select
                  options={marketList}
                  label="Marché(s)"
                  multiple
                  placeholder="Ancien, Neuf..."
                  onChange={(selected) => handleMarketSelect(selected)}
                  value={marketSelected.length > 0 ? marketSelected : null}
                />
              </div>
            )}
            <div className="wrapperbuttonValidate">
              <Button
                tag="button"
                kind="primary"
                size="medium"
                variant="normal"
                onClick={() => {
                  processInformations()
                }}
                disabled={
                  !(
                    (jobSelected !== null &&
                      jobSelected.value !== jobList[0].value) ||
                    marketSelected.length !== 0
                  )
                }
              >
                Valider
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  )
}

export default OverlayFirstConnection
